import { resetFilters } from '../../filters/ad_insertion';
import Events, { EventTypes } from '../../lib/events';
import Logger from '../../lib/logger';
import { clone, extend } from '../../lib/utils';
import Constants from '../../lib/constants';
import { bootDependenciesOfSettings } from '../../lib/settings_dependencies';
import settings from '../../lib/settings';
import Slot from '../../lib/slot';
import GamAdSlot from '../../lib/slots/gam_ad_slot';
import CivicScienceSlot from '../../lib/slots/civic_science_slot';
import ConcertPreviewSlot from '../../lib/slots/concert_preview_slot';
import ConnatixSlot from '../../lib/slots/connatix_slot';
import ReverbSlot from '../../lib/slots/reverb_slot';
import MetabetSlot from '../../lib/slots/metabet_slot';
import OpenWebSlot from '../../lib/slots/open_web_slot';
import OutbrainSlot from '../../lib/slots/outbrain_slot';
import XcoSlot from '../../lib/slots/xco_slot';
import BuyButtonSlot from '../../lib/slots/buy_button_slot';
import NodeResize from '../../lib/node_resize';
import ViewportResize from '../../lib/viewport_resize';
import { conditionExists } from '../../lib/utils';

export const SLOT_TYPES = [
  CivicScienceSlot,
  ConnatixSlot,
  GamAdSlot,
  MetabetSlot,
  OutbrainSlot,
  OpenWebSlot,
  XcoSlot,
  BuyButtonSlot,
  ReverbSlot,
];

class Installer {
  constructor(app, dom, timesInserted) {
    this.app = app;
    this.dom = dom || document;
    this.app.dom = this.dom;

    /**
     * Keep track of how many times a slot is inserted, in case there's a maxSlots
     * filter set on the slot.
     * @type {Object}
     */
    this.timesInserted = timesInserted || {};
  }

  insertSlot(dynamicConfig) {
    const rawConfig = clone(dynamicConfig);
    let config = updateConfigBasedOnConditionl(rawConfig, this.app.settings);

    Logger.log('Using dynamic rule: ' + config.name + ' - ' + config.selector);

    if (configInvalidatedByViewport(config)) {
      Logger.log(`Skipping dynamic rule: ${config.name} - ${config.selector} because of a viewport filter`);
      return;
    }

    // Since NodeList.forEach is not supported in all browsers yet, we're borrowing
    // Array.prototype's forEach method to iterate over each node in the NodeList
    [...this.dom.querySelectorAll(config.selector)].forEach(neighbor => {
      // create a local, mutable copy of the config for this slot instance
      let slotConfig = clone(config);

      if (slotConfig.maxSlots && this.timesInserted[slotConfig.name] >= slotConfig.maxSlots) {
        Logger.log(`${slotConfig.name} wasn't rendered because maxSlots of ${slotConfig.maxSlots} reached`);
        return false;
      }

      if (!Slot.filterSlot(slotConfig, neighbor, this.app.settings)) {
        return false;
      }

      const SlotClass = Constants.CONCERT_PREVIEW ? ConcertPreviewSlot : SLOT_TYPES.find(s => s.isSlotTypeFor(config));

      /**
       * Responsible for determining if this slot config can be inserted or not
       */
      if (SlotClass.shouldSkip(this, slotConfig, neighbor, this.app.settings)) return false;

      const slot = new SlotClass(
        this.app,
        extend(
          {
            index: this.timesInserted[slotConfig.name] || 0,
          },
          slotConfig
        ),
        neighbor
      );

      this.app.slots.push(slot);

      var wrapper = slot.getSlotWrapper({ slot, config: slotConfig });

      if (slotConfig.filters?.loadAfter?.event) {
        isLoadedAfterAnEvent(slotConfig, neighbor, wrapper);
      } else {
        wrapper.insertNextTo(neighbor);
      }

      incrementSlotCount.call(this, slotConfig);

      resetFilters();
    });
  }
}

function updateConfigBasedOnConditionl(config, settings) {
  const conditional = config.conditional;

  return conditionExists(conditional, settings) ? { ...config, ...config.conditional } : config;
}

function isValidEvent(event, slotConfig) {
  if (event && EventTypes[event]) {
    return true;
  } else {
    Logger.warn(`${slotConfig.name} wasn't rendered because ${event} is not a valid event.`);
    return false;
  }
}

function isLoadedAfterAnEvent(slotConfig, neighbor, wrapper) {
  const event = slotConfig.filters?.loadAfter?.event;
  if (event && isValidEvent(event, slotConfig)) {
    Logger.log(`${slotConfig.name} is waiting for the ${event} to load`);
    Events.once(EventTypes[event], () => {
      wrapper.insertNextTo(neighbor);
    });
    return true;
  }
}

/**
 * Increment the number of times a slot is inserted. For DynamicAds usage only
 * to respect the maxSlots param set on the config.
 * @param  {object} config Dynamic config
 * @return {undefined}
 */
function incrementSlotCount(config) {
  this.timesInserted[config.name] = this.timesInserted[config.name] + 1 || 1;
}

/**
 * Check to see if config is invalidated by the current viewport filter.
 * @param {Object} config
 */
function configInvalidatedByViewport(config) {
  if (config.filters && config.filters.viewportWidth) {
    let filter = config.filters.viewportWidth;
    delete config.filters.viewportWidth;

    return !Slot.filterSlot({
      name: config.name,
      filters: {
        viewportWidth: filter,
      },
    });
  }

  return false;
}

export function installMixin(ConcertAds) {
  /**
   * Install Concert Ads
   * @param {Document} dom
   * @param {Boolean} reinstall whether this is a reinstall of ConcertAds
   * @returns null
   */
  ConcertAds.prototype.install = function(dom) {
    if (document.querySelector('#money_pixel_page_level_exception')) {
      return false;
    }

    if (window.disableConcertAdsOnThisPage === true) {
      if (console && console.warn) {
        console.warn(
          "Concert Ads has been disabled on this page, no ads will be shown. I sure hope you know what you're doing"
        );
      } else {
        Logger.warn(
          "Concert Ads has been disabled on this page, no ads will be shown. I sure hope you know what you're doing"
        );
      }
      return false;
    }

    bootDependenciesOfSettings(this);

    this.installer = new Installer(this, dom);
    Events.emit(EventTypes.installing);

    if (!this.settings.slug) {
      Logger.warn("You are missing a 'slug' config, which is required to render Google-served ads. Please add one.");
    }
    Logger.log(`The GAM slug being used is ${this.settings.slug}`);

    // We don't want these set up again on reinstall
    if (!this.hasReinstalled) {
      if (this.settings.nodeResize) new NodeResize(this);
      if (this.settings.viewportResize) new ViewportResize(this);
    }

    this.settings.slots.forEach(config => {
      try {
        this.installer.insertSlot(config);
      } catch (error) {
        Logger.log(`There was an error installing the slot ${error} for config: ${config}`);
      }
    });

    Events.emit(EventTypes.slotsInserted);

    if (this.settings.prebid.biddingEnabled) {
      this.bidManager.init();
    }
  };

  /**
   * Re-install slots in a given area on the page. Defaults to entire document.
   *
   * @param {DOM Node} dom
   */
  ConcertAds.prototype.reinstall = function(dom, updatedSettings) {
    this.uninstall();
    this.hasReinstalled = true;

    if (this.settings.prebid.biddingEnabled) {
      this.bidManager.disable();
    }

    // Resetting any new settings that are passed in
    // but leaving what is already there in place
    if (updatedSettings && typeof updatedSettings === 'object') {
      settings.set({
        ...settings.get(),
        ...updatedSettings,
      });
    }

    this.install(dom);
  };

  /**
   * Uninstall slots from the page.
   */
  ConcertAds.prototype.uninstall = function() {
    this.slots.all().forEach(slot => slot.destroy());
    this.slots.clear();
  };

  /**
   * Insert slots in a given area on the page. Defaults to all slots and entire document.
   *
   * @param {String} domSelector
   * @param {Array} includedSlots
   * @param {Array} excludedSlots
   */
  ConcertAds.prototype.insertSlots = function({ domSelector, includedSlots = [], excludedSlots = [] } = {}) {
    const dom = document.querySelector(domSelector) ? document.querySelector(domSelector) : document;
    const slots = includedSlots.length
      ? this.settings.slots.filter(slot => includedSlots.includes(slot.name))
      : this.settings.slots.filter(slot => !excludedSlots.includes(slot.name));

    this.installer = new Installer(this, dom, this.installer.timesInserted);
    slots.forEach(config => {
      if (config.insertSlot === false && !includedSlots.includes(config.name)) return;
      this.installer.insertSlot(config);
    });
  };
}
