/**
 * Uses the performance.mark browser API to mark when events happen in the
 * browser.
 * @param  {String} name The name of the event we want to mark.
 */
export function performanceMark(name) {
  if (typeof performance === 'object' && typeof performance.mark === 'function') {
    performance.mark(`ad_${name}`);
  }
  return true;
}

/**
 * Checks for advertising consent within the OneTrust OptanonConsent cookie
 * @returns {Boolean}
 */
export function checkOneTrustCookieConsent() {
  try {
    // If OneTrust object exists, use it directly
    if (window.OnetrustActiveGroups) {
      return window.OnetrustActiveGroups.includes('C0003');
    }

    // Fallback to cookie check
    const match = document.cookie.match(/OptanonConsent=([^;]+)/);
    if (match) {
      const decodedValue = decodeURIComponent(match[1]);
      const groupsMatch = decodedValue.match(/groups=([^&]+)/);
      if (groupsMatch) {
        const groups = groupsMatch[1];
        return groups.includes('C0003:1');
      }
    }

    return false;
  } catch (error) {
    return false;
  }
}

/**
 * Uses the performance.mark browser API to mark when events happen in the
 * browser.
 * @param  {String} name The name of the event we want to mark.
 */
export function performanceMeasure(name, start, end) {
  if (!start || !end) {
    var generated = getPerformanceMeasureDefaults(name);
    start = start || generated.start;
    end = end || generated.end;
  }
  if (typeof performance === 'object' && typeof performance.measure === 'function') {
    if (anyPerformanceMarksAreUndefined(`ad_${start}`, `ad_${end}`)) {
      return true;
    }

    performance.measure(`ad_${name}`, `ad_${start}`, `ad_${end}`);
  }
  return true;
}

/**
 * Test if any performance marks are not currently defined on the page
 * @param {String} start
 * @param {String} end
 * @returns
 */
function anyPerformanceMarksAreUndefined(start, end) {
  const marks = [start, end];
  const marksOnPage = performance.getEntriesByType('mark');

  return marks.some(mark => {
    return !Object.keys(marksOnPage).some(markKey => marksOnPage[markKey].name === mark);
  });
}

/**
 * A quick test to know whether a value/string includes PII. Used to know whether
 * we can send ads for a page.
 * @param  {string} value A value that be tested as an argument in `RexExp.test`
 * @return {boolean}
 */
export function containsPii(value) {
  const PII_REGEX = /\S+(@|%40)\S+\.\S+/;
  return PII_REGEX.test(value);
}

/**
 * Converts snake_case or kebab-case to PascalCase
 * @param  {string} value A value to be converted
 * @return {string}
 */
export function convertToPascal(string) {
  const SNAKE_REGEX = /([-_][a-z])/gi;

  const capitalizedString = string.charAt(0).toUpperCase() + string.slice(1);
  return capitalizedString.replace(SNAKE_REGEX, val => {
    return val
      .toUpperCase()
      .replace('_', '')
      .replace('-', '');
  });
}

/**
 * A quick test to know whether we should use transitionEnd or webkitTransitionEnd
 * @return {string}
 */
export function transitionEndEvent() {
  var el = document.createElement('div');

  var transitions = {
    transition: 'transitionend',
    WebkitTransition: 'webkitTransitionEnd',
  };

  for (var t in transitions) {
    if (el.style[t] !== undefined) {
      return transitions[t];
    }
  }
}

/**
 * Debounces a function to only run after repeated invocations have stopped.
 * @param {Function} function to debounce.
 * @param {Number} time in milliseconds to delay invocation.
 * @param {Object} binding context in which the function is invoked.
 * @returns {Function} the debounced function.
 */
export function debounce(fn, delay, context) {
  var timer;
  return function() {
    var callTarget = context || this;
    var callArgs = arguments;
    clearTimeout(timer);
    timer = setTimeout(function() {
      fn.apply(callTarget, callArgs);
    }, delay);
  };
}

/**
 * Gets the current viewport width.
 * @return {Integer} Viewport width
 */
export function getViewportWidth() {
  return Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
}

/**
 * Gets the canonical url
 * @param {string} url
 * @return {string} url
 */
export function getCanonicalUrl(url = null) {
  const canonicalElement = document.querySelector('link[rel="canonical"][href]');
  const canonicalURL = canonicalElement ? canonicalElement.href : null;
  return url || canonicalURL || window.location.href;
}

/**
 * Checks for touch capabilities and screen size.
 * @return {String} Device type (desktop, mobile, tablet, <other>)
 */
export function getDeviceType() {
  // How we define "touch". Mostly what is in Modernizr, with some Chorus flare.
  /* eslint-disable */
  var isTouch =
    'ontouchstart' in window ||
    (window.DocumentTouch && document instanceof DocumentTouch) ||
    navigator.msMaxTouchPoints > 0;
  var context = 'desktop';

  // If we have a touch screen, with width less than 728— OR we put it in the
  // url that we want mobile.
  if ((isTouch && window.innerWidth < 728) || /mobile=true/.test(window.location.search)) {
    context = 'mobile';
  } else if (isTouch) {
    // If not a mobile device, and has touch, then a tablet.
    context = 'tablet';
  }

  // If we are inside an iframe (previewing an entry in Anthem), and the iframe
  // dictates a device type attribute, respect that.
  try {
    if (window.frameElement && window.frameElement.getAttribute('data-device-type') != null) {
      context = window.frameElement.getAttribute('data-device-type');
    }
  } catch (e) {
    // Swallow any security related errors that might arise from accessing window.frameElement
  }

  return context;
}

/**
 * A test to see if a User Agent string contains a webview-like agent
 * @param  {String} uastring  User agent string
 * @return {String}           The type of user agent, or false
 */
export function getWebView(uastring) {
  let webview = false;

  if (uastring.match(/Twitter/)) {
    webview = 'twitter';
  } else if (uastring.search('FBAN') >= 0) {
    webview = 'facebook';
  } else if (uastring.match(/Pinterest/)) {
    webview = 'pinterest';
  } else if (uastring.match(/AppleWebKit/) && !uastring.match(/Safari/)) {
    webview = 'undetermined';
  }

  return webview;
}

export const SESSION_DEPTH_KEY = 'ChorusSessionDepth';

/**
 * Get the a stored session depth.
 *
 * @return {Integer} Session depth
 */
export function getSessionDepth() {
  let sessionDepth = 0;

  try {
    sessionDepth = sessionStorage.getItem(SESSION_DEPTH_KEY) || 0;
  } catch (e) {
    // Do nothing
  }

  return sessionDepth;
}

/**
 * Increment the session depth.
 */
export function incrementSessionDepth() {
  let sessionDepth = getSessionDepth();

  try {
    sessionStorage.setItem(SESSION_DEPTH_KEY, ++sessionDepth);
  } catch (e) {
    // Do nothing
  }
}

/**
 * Calculates the top of the elements offset from the bottom of the viewport
 * as a fraction of the total viewport height.
 * A negative number denotes below the viewport, whereas a positive number
 * is the distance from the elements top to the bottom of the viewport
 * A number above 1.0 means it might no longer be in the viewport
 *
 * @param  {Dom Element} element An element to measure
 * @return {integer}  Distance of the top of element to bottom of viewport
 */
export function distanceFromBottom(element) {
  const viewportHeight = Math.max(window.innerHeight || 1);
  return (viewportHeight - element.getBoundingClientRect().top) / viewportHeight;
}

/**
 * Calculates the bottom of the elements offset from the bottom of the viewport
 * as a fraction of the total viewport height.
 * A negative number denotes below the viewport, whereas a positive number
 * is the distance from the elements bottom to the bottom of the viewport
 * A number above 1.0 means it might no longer be in the viewport
 *
 * @param  {Dom Element} element An element to measure
 * @return {integer}  Distance of the bottom of element to bottom of viewport
 */
export function distanceOfElementBottomFromViewportBottom(element) {
  const viewportHeight = Math.max(window.innerHeight || 1);
  return (viewportHeight - element.getBoundingClientRect().bottom) / viewportHeight;
}

/**
 * Given a number and set of options it will return the closes number
 * in the set of allowable options
 *
 * @param  {Number} input     the input number
 * @param  {Array} allowable  the allowable set of responses
 * @return {Number}           the closest matching number from the allowable responses
 */
export function closestTo(input, allowable = []) {
  const sorted = (allowable || []).sort((a, b) => {
    return a - b;
  });

  if (sorted.length == 0) {
    return input;
  }

  if (input < sorted[0]) {
    return sorted[0];
  }

  if (input > sorted[sorted.length - 1]) {
    return sorted[sorted.length - 1];
  }

  let closestProximity = Infinity;
  let closestAllowable = input;
  for (let i = 0; i < sorted.length; i++) {
    const proximity = Math.abs(input - sorted[i]);
    if (proximity < closestProximity) {
      closestProximity = proximity;
      closestAllowable = sorted[i];
    }
  }

  return closestAllowable;
}

/**
 * Extends a base object with properties from additional passed objects:
 * @param {Object} base object to extend.
 * @param {...Object} objects to extend properties from.
 * @returns {Object} the base object, extended by all other argument objects.
 */
export function extend(base) {
  for (var i = 1; i < arguments.length; i++) {
    var ext = arguments[i];
    for (var key in ext) {
      if (ext.hasOwnProperty(key)) base[key] = ext[key];
    }
  }
  return base;
}

/**
 * Clones a base object. Allows you to create a copy of a nested object
 * without passing it by reference.
 * @param   {Object} Base Object
 * @returns {Object} Cloned object
 */
export function clone(obj) {
  return JSON.parse(JSON.stringify(obj));
}

/**
 * Determines if the argument is a object
 * @returns {Boolean}
 */
export function isObject(obj) {
  return obj && typeof obj === 'object';
}

/**
 * Convenience function for dynamically generating measure keys
 * @param  {String} name The name of the event we want to mark.
 * @return {object} start/end names of measure
 */
function getPerformanceMeasureDefaults(name) {
  return {
    start: `${name}-start`,
    end: `${name}-end`,
  };
}

/**
 * Merges two objects together, if they share the same top level keys
 * then their values will be combined into an array, if their values are different
 *
 * This will merge the following two objects:
 *   {a: 1}, {a:2} --->     { a: [1, 2] }
 *   {a: 1}, {a:1} --->     { a: 1 }
 *   {a: [1,2]}, {a:3} ---> { a: [1, 2, 3] }
 *
 * @param {Object} original The base object
 * @param {Object} incoming The incoming object to merge and create arrays on overlapping keys
 * @return {Object} a merged object
 */
export function mergeByMakingArraysOfValuesWithTheSameKey(original, incoming) {
  let mergedResult = { ...incoming, ...original };
  const matchingKeys = Object.keys(incoming).filter(k => Object.keys(original).includes(k));

  matchingKeys.forEach(key => {
    if (mergedResult[key] !== incoming[key]) {
      if (Array.isArray(mergedResult[key])) {
        // Append the values to the existing array and filter out duplicates.
        mergedResult[key] = mergedResult[key].concat(incoming[key]).filter((val, i, all) => all.indexOf(val) === i);
      } else {
        // Create a 2 element array with the previous and incoming value.
        mergedResult[key] = [mergedResult[key], incoming[key]];
      }
    }
  });

  return mergedResult;
}

export function conditionExists(conditional, settings) {
  try {
    if (conditional && typeof conditional.condition === 'object') {
      const conditionType = Object.keys(conditional.condition)[0];

      if (conditionType === 'useIfSubscription') {
        return Boolean(settings.hasSubscription);
      } else if (conditionType === 'useIfSelector') {
        return Boolean(document.querySelector(conditional.condition[conditionType]));
      } else if (conditionType === 'useIfLoggedIn') {
        return Boolean(settings.loggedIn);
      } else {
        return false;
      }
    }
    return false;
  } catch (e) {
    return false;
  }
}

/**
 * Detect devtools within the browser.
 */
export const devtools = window.__GOLDBUG_GLOBAL_HOOK__;
