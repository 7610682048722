import Logger from '../lib/logger';

export default {
  localStorage(options = {}) {
    return new Promise(resolve => {
      const { storageKey } = options;

      if (!storageKey) {
        Logger.error(`Skipping classifier localStorage because it is missing a 'storageKey'`);
        return resolve(false);
      }

      const value = localStorage.getItem(storageKey);

      if (value === null) {
        Logger.log(`Skipping classifier localStorage because item ${storageKey} is empty`);
        resolve(false);
      }

      if (value[0] === '{' || value[0] === '[') {
        try {
          return resolve(JSON.parse(value));
        } catch (e) {
          return resolve(value);
        }
      }

      resolve(value);
    });
  },

  /**
   * Get logged in status
   *
   * @param  {{ endpoint: string }} options
   * @return {Promise<{[key: string]: string|string[]}>} Returns an object with targeting key/values.
   */
  loggedInStatus() {
    return new Promise(resolve => {
      const loggedIn = this.settings.loggedIn || false;

      if (loggedIn) {
        resolve({
          login: true,
        });
      } else {
        resolve(false);
      }
    });
  },

  /**
   * If the user is in Chrome and the cookieDeprecationLabel API is available,
   * it detects the cookieDeprecationLabel to be added to targeting values
   * @returns {String} label
   */
  getChromeCookieDeprecation() {
    return new Promise(resolve => {
      // Feature detect temporary API first
      if ('cookieDeprecationLabel' in navigator) {
        // Request value and resolve promise
        navigator.cookieDeprecationLabel.getValue().then(label => {
          if (typeof label === 'string') {
            resolve(label);
          } else {
            resolve(false);
          }
        });
      } else {
        resolve(false);
      }
    });
  },
};
